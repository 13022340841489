.box {
    padding: 1rem;
    padding-top: 0rem;
    display: inline-block;
    position: relative;
    width: fit-content;
    height: fit-content;
    background-color: #fff;

    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.box.shadow-none {
    box-shadow: none;
}

.box.shadow-1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.box.shadow-2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.box.shadow-3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.box.shadow-4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.box.shadow-5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
