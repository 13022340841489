.table {
    /* position: relative; */
    z-index: 10;
    background: transparent;
}

.tableRow {
    position: relative;
    z-index: 10;
    background: transparent;
}

.highlightedRow .tableCell::after {
    content: "";

    display: inline-block;
    position: absolute;
    z-index: 0;
    background: rgba(173, 216, 230, 1);
    pointer-events: none;
    width: 100%;
    height: calc(100% + 2px);
    top: -2px;
    left: 0;
}

.tableRow:not(:last-of-type):after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 1px;
    background: gray;
    border-radius: 2px;

    bottom: 0;
    left: 0;
}

.tableHeader:first-of-type:after {
    height: 2px;
    background: black;
}

.tableCell {
    z-index: 10;
    padding: 0.5rem;
    background: transparent;
}

.tableCell * {
    z-index: 10;
    position: relative;
}

.tableCell.centered {
    text-align: center;
}

.tableCell:not(:last-child) {
    border-right: 1px dotted black;
}

.tablerHeaderCell {
    border-bottom: 10px dotted gray;
}
